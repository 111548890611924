import { AxiosResponse } from 'axios';

import API from '../constants/api';
import { AxiosClient, AxiosGeocodingClient } from '../utils/axiosClient';

import { ResponseType, CountriesResponse, PostCodeResponse, GeocodingResponse } from './apiTypes';

const commonServices = {
    getLocation: async () => {
        const [countryRes, postcodeRes] = await Promise.all<
            AxiosResponse<ResponseType<{ countries: CountriesResponse[] } | null>>,
            AxiosResponse<ResponseType<{ postcodes: PostCodeResponse[] } | null>>
        >([AxiosClient.get(API.GET_ALL_COUNTRIES), AxiosClient.get(API.GET_ALL_POST_CODES)]);
        return { countryRes: countryRes.data, postcodeRes: postcodeRes.data };
    },

    getCurrentLocation: async (params: { latitude: number; longitude: number }): Promise<GeocodingResponse> => {
        const { data } = await AxiosGeocodingClient.get(API.GEOCODING, { params });
        return data;
    },
};

export default commonServices;
