import { SortType } from '../../../common/constants/enum';

export const SET_EXCLUDED_ALLERGEN = 'SET_EXCLUDED_ALLERGEN';
export const SET_EXCLUDED_INGREDIENT = 'SET_EXCLUDED_INGREDIENT';
export const SET_EXCLUDED_TYPE = 'SET_EXCLUDED_TYPE';
export const SET_EXCLUDED_MODE = 'SET_EXCLUDED_MODE';
export const SET_DELETED_ID_LIST = 'SET_DELETED_ID_LIST';

export const GET_CUSTOMER_ALLERGEN_LIST = 'GET_CUSTOMER_ALLERGEN_LIST';
export const SET_CUSTOMER_ALLERGEN_LIST = 'SET_CUSTOMER_ALLERGEN_LIST';

export const GET_CUSTOMER_INGREDIENT_LIST = 'GET_CUSTOMER_INGREDIENT_LIST';
export const SET_CUSTOMER_INGREDIENT_LIST = 'SET_CUSTOMER_INGREDIENT_LIST';

export interface GetCustomerAllergenList {
    type: typeof GET_CUSTOMER_ALLERGEN_LIST;
    payload: { sortDirection?: SortType };
}

export interface GetCustomerIngredientList {
    type: typeof GET_CUSTOMER_INGREDIENT_LIST;
    payload: { sortDirection?: SortType };
}
