import { AxiosAdminClient } from '../utils/axiosClient';
import API from '../constants/api';
import { LoginFields } from '../../pages/admin/login/AdminLogin';
import { TableConfig } from '../../components/Table/Table';
import { CountryFields } from '../../pages/admin/country-settings/components/ActionDialog';
import { TableBranchConfig } from '../../pages/admin/retailers/components/Branches/Branches';
import { TableProductByBranchConfig } from '../../pages/admin/retailers/components/Products/Products';
import { TableProductViews } from '../../pages/admin/product-views/ProductViews';
import { TableViewsConfig } from '../../pages/admin/product-views/ProductViewsBreakdown';
import { TableIngredientConfig } from '../../pages/admin/allergens/AllergensHelper';

import {
    AdminBranchesTableResponse,
    AdminCountriesResponse,
    AdminCountriesTableResponse,
    AdminLoginResponse,
    AdminProductDetailResponse,
    AdminProductTableResponse,
    AdminProductByBranchTableResponse,
    CategoryDTO,
    ResponseType,
    ProductViewsTableResponse,
    LogViewsTableResponse,
    LogTopViewsResponse,
    AllergenDTO,
    IngredientDTO,
    ListIngredientFromAllergens,
    PostIngredientFromAllergens,
    ApiStatusMonitorResponse,
    ApiStatusNotificationResponse,
    ApiStatusMonitor,
    SyncType,
    StatusNotificationResponse,
} from './apiTypes';

const adminServices = {
    login: async (params: LoginFields): Promise<ResponseType<AdminLoginResponse | null>> => {
        const { data } = await AxiosAdminClient.post(API.ADMIN.LOGIN, params);
        return data;
    },
    getCountrySettings: async (params: TableConfig): Promise<AdminCountriesTableResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.COUNTRY.GET_ALL, {
            params,
        });
        return data;
    },
    getCountryById: async (id: string): Promise<AdminCountriesResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.COUNTRY.GET_BY_ID + '/' + id);
        return data;
    },
    deleteCountry: async (params: { id: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosAdminClient.delete(API.ADMIN.COUNTRY.DELETE, {
            data: {
                id: params.id,
            },
        });
        return data;
    },
    updateCountry: async (params: CountryFields): Promise<AdminCountriesResponse> => {
        const { data } = await AxiosAdminClient.put(API.ADMIN.COUNTRY.UPDATE_BY_ID, {
            ...params,
        });
        return data;
    },
    createCountry: async (params: CountryFields): Promise<AdminCountriesResponse> => {
        const { data } = await AxiosAdminClient.post(API.ADMIN.COUNTRY.CREATE, {
            ...params,
        });
        return data;
    },
    getListCategory: async (params?: {
        parentId?: string | null;
        searchValue?: string;
    }): Promise<ResponseType<{ categories: CategoryDTO[] } | null>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.GET_LIST_CATEGORY, { params });
        return data;
    },

    createCategory: async (params: {
        name: string;
        parentId?: string | null;
    }): Promise<ResponseType<{ category: CategoryDTO | null }>> => {
        const { data } = await AxiosAdminClient.post(API.ADMIN.CREATE_CATEGORY, params);
        return data;
    },

    updateCategory: async (params: { id: string; name: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosAdminClient.put(API.ADMIN.UPDATE_CATEGORY, params);
        return data;
    },

    deleteCategory: async (id: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosAdminClient.delete(`${API.ADMIN.DELETE_CATEGORY}/${id}`);
        return data;
    },

    findAllProductByCategory: async (params: TableConfig): Promise<AdminProductTableResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.PRODUCT.FIND_ALL_BY_CATEGORY, { params });
        return data;
    },

    findProductById: async (id: string): Promise<AdminProductDetailResponse> => {
        const { data } = await AxiosAdminClient.get(`${API.ADMIN.PRODUCT.FIND_BY_ID}/${id}`);
        return data;
    },

    getBranches: async (params: TableBranchConfig): Promise<AdminBranchesTableResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.BRANCH.GET_ALL, {
            params,
        });
        return data;
    },

    getProductByBranch: async (params: TableProductByBranchConfig): Promise<AdminProductByBranchTableResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.PRODUCT.FIND_ALL_BY_BRANCH, {
            params,
        });
        return data;
    },

    getTotalNumberProduct: async (): Promise<ResponseType<number>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.PRODUCT.GET_TOTAL_NUMBER_PRODUCT);
        return data;
    },

    getTotalNumberBranch: async (): Promise<ResponseType<number>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.BRANCH.GET_TOTAL_NUMBER_BRANCH);
        return data;
    },

    getProductViews: async (params: TableProductViews): Promise<ProductViewsTableResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.PRODUCT.GET_PRODUCT_VIEWS, { params });
        return data;
    },

    generatorFileProductViews: async (params: TableProductViews): Promise<any> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.PRODUCT.GET_PRODUCT_VIEWS, { params });
        return data;
    },

    generatorFileProductViewsDetail: async (params: TableViewsConfig): Promise<any> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.LOG_VIEW.GET_BY_UPC, { params });
        return data;
    },

    getByUpc: async (params: TableViewsConfig): Promise<LogViewsTableResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.LOG_VIEW.GET_BY_UPC, {
            params,
        });
        return data;
    },

    getTopViewedByUpc: async (params: { upc: string }): Promise<LogTopViewsResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.LOG_VIEW.GET_TOP_VIEWS_BY_UPC, { params });
        return data;
    },

    getListIngredientByName: async (name: string): Promise<ResponseType<IngredientDTO[]>> => {
        const { data } = await AxiosAdminClient.get(`${API.ADMIN.INGREDIENT.GET_LIST_BY_NAME}/${name}`);
        return data;
    },

    getListIngredientByAllergens: async (
        params: TableIngredientConfig,
    ): Promise<ResponseType<ListIngredientFromAllergens>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.INGREDIENT.GET_LIST_BY_ALLERGENS, { params });
        return data;
    },

    createIngredientOfAllergen: async (params: {
        allergenId: string;
        ingredientId: string;
    }): Promise<ResponseType<PostIngredientFromAllergens>> => {
        const { data } = await AxiosAdminClient.post(API.ADMIN.INGREDIENT.CREATE_OF_ALLERGEN, params);
        return data;
    },

    deleteIngredientFromAllergen: async (params: { id: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosAdminClient.delete(API.ADMIN.INGREDIENT.DELETE_FROM_ALLERGEN, { params });
        return data;
    },

    createAllergen: async (params: { name: string }): Promise<ResponseType<{ allergen: AllergenDTO }>> => {
        const { data } = await AxiosAdminClient.post(API.ADMIN.ALLERGEN.CREATE, params);
        return data;
    },

    deleteAllergen: async (id: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosAdminClient.delete(`${API.ADMIN.ALLERGEN.DELETE}/${id}`);
        return data;
    },

    getListAllergen: async (params?: { searchName?: string }): Promise<ResponseType<AllergenDTO[]>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.ALLERGEN.GET_LIST, { params });
        return data;
    },

    getListAllergenByIngredient: async (params: { searchName: string }): Promise<ResponseType<AllergenDTO[]>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.ALLERGEN.GET_LIST_BY_INGREDIENT, { params });
        return data;
    },

    getApiStatusMonitor: async (params: TableConfig): Promise<ApiStatusMonitorResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.API_STATUS.MONITOR, { params });
        return data;
    },

    getApiStatusNotification: async (): Promise<ApiStatusNotificationResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.API_STATUS.NOTIFICATION);
        return data;
    },

    getApiStatusNotificationPagination: async (params: {
        page: number;
        pageSize: number;
    }): Promise<StatusNotificationResponse> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.API_STATUS.NOTIFICATION_PAGINATION, { params });
        return data;
    },

    updateApiStatusNotification: async (id: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosAdminClient.post(API.ADMIN.API_STATUS.UPDATE_NOTIFICATION, { id });
        return data;
    },

    getCurrentApiStatus: async (params: { type: SyncType }): Promise<ResponseType<{ status: ApiStatusMonitor }>> => {
        const { data } = await AxiosAdminClient.get(API.ADMIN.API_STATUS.GET_CURRENT_STATUS, { params });
        return data;
    },
};

export default adminServices;
