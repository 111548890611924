import { all, call, put, takeEvery } from 'redux-saga/effects';

import adminServices from '../../../common/services/adminServices';
import { AllergenDTO, ResponseType } from '../../../common/services/apiTypes';

import { setAllergenList } from './actions';
import { ALLERGEN_ACTION } from './actionTypes';

function* getAllergenList() {
    try {
        const response: ResponseType<AllergenDTO[]> = yield call(adminServices.getListAllergen);
        yield put(setAllergenList(response.data));
    } catch (error) {}
}

function* watchAll() {
    yield all([takeEvery(ALLERGEN_ACTION.GET_LIST, getAllergenList)]);
}

export default watchAll();
