import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
    BranchByWishlistDTO,
    ProductByWishlistBranchDTO,
    ResponseType,
    WishlistDTO,
} from '../../../common/services/apiTypes';
import userServices from '../../../common/services/userServices';
import { notify } from '../../../common/utils/notify';

import { setActiveBranchList, setActiveProductList, setWishlist, updateActiveProductListSuccess } from './actions';
import {
    GetActiveBranchList,
    GetActiveProductList,
    GET_ACTIVE_BRANCH_LIST,
    GET_ACTIVE_PRODUCT_LIST,
    GET_WISHLIST,
    UpdateActiveProductList,
    UPDATE_ACTIVE_PRODUCT_LIST,
} from './actionTypes';

function* getWishlist() {
    try {
        const response: ResponseType<WishlistDTO[]> = yield call(() => userServices.getListWishlist());
        yield put(setWishlist(response.data || []));
    } catch (error) {}
}

function* getActiveProductList(action: GetActiveProductList) {
    try {
        const response: ResponseType<ProductByWishlistBranchDTO[]> = yield call(() =>
            userServices.getListProductOfWishlist({ wishlistId: action.payload.wishlistId }),
        );
        yield put(setActiveProductList(response.data || []));
    } catch (error) {
        notify.error('Have error, please try again', 'Error');
    }
}

function* getActiveBranchList(action: GetActiveBranchList) {
    try {
        const response: ResponseType<BranchByWishlistDTO[]> = yield call(() =>
            userServices.getListProductOfWishlistByBranch({
                wishlistId: action.payload.wishlistId,
                showByBranch: true,
            }),
        );
        yield put(setActiveBranchList(response.data || []));
    } catch (error) {
        notify.error('Have error, please try again', 'Error');
    }
}

function* updateProductList(action: UpdateActiveProductList) {
    try {
        const response: ResponseType<ProductByWishlistBranchDTO[]> = yield call(() =>
            userServices.updateWishlistProductList(action.payload),
        );
        if (response.success) {
            yield put(updateActiveProductListSuccess(action.payload));
        } else {
            notify.error(response.message, 'Error');
        }
    } catch (error) {
        notify.error('Have error, please try again', 'Error');
    }
}

function* watchAll() {
    yield all([
        takeEvery(GET_WISHLIST, getWishlist),
        takeEvery(UPDATE_ACTIVE_PRODUCT_LIST, updateProductList),
        takeEvery(GET_ACTIVE_PRODUCT_LIST, getActiveProductList),
        takeEvery(GET_ACTIVE_BRANCH_LIST, getActiveBranchList),
    ]);
}

export default watchAll();
