import { all, call, put, takeEvery } from 'redux-saga/effects';

import userServices from '../../../common/services/userServices';
import { ResponseType, CategoryEndUserResponse } from '../../../common/services/apiTypes';

import { CATEGORY_ACTION } from './actionTypes';
import { getCategorySuccess } from './actions';

function* getCategories() {
    try {
        const response: ResponseType<CategoryEndUserResponse> = yield call(() => userServices.getCategories());
        yield put(getCategorySuccess(response.data.categories));
    } catch (error) {}
}

function* watchAll() {
    yield all([takeEvery(CATEGORY_ACTION.GET_CATEGORIES, getCategories)]);
}

export default watchAll();
