import { CountriesResponse, PostCodeResponse } from '../../common/services/apiTypes';

import { LocationType, GET_LOCATION_SUCCESS, SET_CURRENT_LOCATION } from './actionTypes';

export interface LocationState {
    countries: CountriesResponse[];
    postCodes: PostCodeResponse[];
    currentCountry: string;
    currentState: string;
}

const initialState: LocationState = {
    postCodes: [],
    countries: [],
    currentCountry: '',
    currentState: '',
};

const reducer = (state = initialState, action: LocationType): LocationState => {
    switch (action.type) {
        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                countries: action.payload.countries,
                postCodes: action.payload.postcodes,
            };
        case SET_CURRENT_LOCATION:
            return {
                ...state,
                currentCountry: action.payload.currentCountry,
                currentState: action.payload.currentState,
            };
        default:
            return state;
    }
};

export default reducer;
