import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { AllergenDTO, IngredientFromAllergens } from '../../../common/services/apiTypes';
import { produceReducer } from '../../wrapper';

import { ALLERGEN_ACTION, SET_IS_TABLE_NEED_RELOAD, SET_SELECTED_INGREDIENTS } from './actionTypes';

export interface AllergenAdminState {
    selectedIngredients: IngredientFromAllergens[];
    isTableNeedReload: boolean;
    allergenList: AllergenDTO[];
    selectedAllergen: AllergenDTO | undefined;
}

const initialState: AllergenAdminState = {
    selectedIngredients: [],
    isTableNeedReload: false,
    allergenList: [],
    selectedAllergen: undefined,
};

const actionHandlers = {
    [ALLERGEN_ACTION.SET_LIST]: (draftStore: AllergenAdminState, action: Action<AllergenDTO[]>) => {
        draftStore.allergenList = action.payload;
    },
    [ALLERGEN_ACTION.SET_SELECTED_ALLERGEN]: (draftStore: AllergenAdminState, action: Action<AllergenDTO>) => {
        draftStore.selectedAllergen = action.payload;
    },
    [SET_IS_TABLE_NEED_RELOAD]: (draftStore: AllergenAdminState, action: Action<boolean>) => {
        draftStore.isTableNeedReload = action.payload;
    },
    [SET_SELECTED_INGREDIENTS]: (draftStore: AllergenAdminState, action: Action<IngredientFromAllergens[]>) => {
        draftStore.selectedIngredients = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<AllergenAdminState>;
