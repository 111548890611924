import { AxiosClient } from '../utils/axiosClient';
import { SortType } from '../constants/enum';
import API from '../constants/api';
import { LoginParams, LoginResponse, VerificationResetPasswordResponse } from '../../redux/auth/actionTypes';
import { TableConfig } from '../../components/Table/Table';
import { ProductListConfig } from '../../pages/user/product/ListProduct';
import { ChangePasswordFields } from '../../pages/user/profile/change-password/ChangePassword';
import { LogViewProductBranchFields } from '../../pages/user/product-detail/ProductDetail';
import { ScheduleProps } from '../../pages/user/profile/consumption-tracker/components/ScheduleComponent/ScheduleComponent';
import {
    CheckProductResponseType,
    ConsumptionTrackerCreateType,
    ConsumptionTrackerUpdateType,
    ProductConsumptionTrackerResopnse,
} from '../../pages/user/profile/consumption-tracker/components/CreateConsumptionTracker/CreateConsumptionTracker';
import { ConsumptionTrackerDisplay } from '../../pages/user/profile/consumption-tracker/components/CalendarComponent/CalendarComponent';

import {
    AllergenDTO,
    BranchByWishlistDTO,
    BranchDTO,
    CategoryEndUserResponse,
    CreateProductByWishlistBranchResponse,
    IngredientDTO,
    LogViewProductBranchDTO,
    ProductByWishlistBranchDTO,
    ProductDetailResponse,
    ResponseType,
    SignUpRequest,
    SignUpResponse,
    UpdateProfileRequest,
    UserInfoDTO,
    UserProductTableResponse,
    WishlistDTO,
    UpdateWishlistProductListParams,
    AllergenByCustomer,
    IngredientByCustomer,
    IngredientFromAllergens,
    ConsumptionTrackerDTO,
} from './apiTypes';

const userServices = {
    login: async (params: LoginParams): Promise<ResponseType<LoginResponse | null>> => {
        const { data } = await AxiosClient.post(API.LOGIN, params);
        return data;
    },
    signUp: async (params: SignUpRequest): Promise<ResponseType<SignUpResponse | null>> => {
        const { data } = await AxiosClient.post(API.SIGN_UP, params);
        return data;
    },
    verifyCodeToSignUpOrActiveAccount: async (params: {
        code: string;
        email: string;
    }): Promise<ResponseType<LoginResponse | null>> => {
        const { data } = await AxiosClient.post(API.VERIFY_CODE_TO_SIGN_UP, params);
        return data;
    },
    verifyCodeToResetPassword: async (params: {
        code: string;
        email: string;
    }): Promise<ResponseType<VerificationResetPasswordResponse>> => {
        const { data } = await AxiosClient.post(API.VERIFY_CODE_TO_RESET_PASSWORD, params);
        return data;
    },
    resetPassword: async (params: {
        email: string;
        code: string;
        newPassword: string;
        confirmationPassword: string;
    }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.post(API.CUSTOMER_RESET_PASSWORD, params);
        return data;
    },

    sendVerifyCode: async (params: { email: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.post(API.SEND_VERIFY_CODE, params);
        return data;
    },
    getUserInfo: async (): Promise<ResponseType<UserInfoDTO | null>> => {
        const { data } = await AxiosClient.get(API.GET_USER_INFO);
        return data;
    },
    getProductById: async (params: { id: string }): Promise<ProductDetailResponse> => {
        const { data } = await AxiosClient.get(`${API.GET_PRODUCT_BY_ID}${params.id}`);
        return data;
    },
    getBranchByProductId: async (id: string, state: string): Promise<ResponseType<BranchDTO[]>> => {
        const { data } = await AxiosClient.get(API.GET_BRANCH_BY_PRODUCT_ID, {
            params: { productId: id, state },
        });
        return data;
    },
    getByBranchId: async (productId: string, branchId: string): Promise<ProductDetailResponse> => {
        const { data } = await AxiosClient.get(API.GET_BY_BRANCH_ID, {
            params: { productId, branchId },
        });
        return data;
    },
    getCategories: async (params?: { parentId?: string }): Promise<ResponseType<CategoryEndUserResponse>> => {
        const { data } = await AxiosClient.get(API.GET_ALL_CATEGORIES, { params });
        return data;
    },
    getProducts: async (params: ProductListConfig): Promise<UserProductTableResponse> => {
        const { data } = await AxiosClient.get(API.GET_ALL_PRODUCT_BY_CATEGORY, { params });
        return data;
    },
    writeLogViewProduct: async (
        params: LogViewProductBranchFields,
    ): Promise<ResponseType<LogViewProductBranchDTO[]>> => {
        const { data } = await AxiosClient.post(API.WRITE_LOG_VIEW_PRODUCT, {
            ...params,
        });
        return data;
    },
    getProductsByTopView: async (params: TableConfig): Promise<UserProductTableResponse> => {
        const { data } = await AxiosClient.get(API.GET_PRODUCTS_BY_LOG_VIEW, { params });
        return data;
    },
    getListAllergen: async (): Promise<ResponseType<AllergenDTO[]>> => {
        const { data } = await AxiosClient.get(API.ALLERGEN.GET_LIST);
        return data;
    },
    getIngredientsByName: async (name: string): Promise<ResponseType<IngredientDTO[]>> => {
        const { data } = await AxiosClient.get(`${API.INGREDIENT.GET_BY_NAME}/${name}`);
        return data;
    },

    updateProfile: async (params: UpdateProfileRequest): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.CUSTOMER.UPDATE_PROFILE, params);
        return data;
    },

    changePassword: async (params: ChangePasswordFields): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.CUSTOMER.CHANGE_PASSWORD, params);
        return data;
    },

    updateAvatar: async (file: File): Promise<ResponseType<string>> => {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await AxiosClient.post(`${API.CHANGE_AVATAR}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    },
    deleteAvatar: async (): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.DELETE_AVATAR}`);
        return data;
    },

    getListWishlist: async (params?: { searchValue: string }): Promise<ResponseType<WishlistDTO[]>> => {
        const { data } = await AxiosClient.get(API.WISHLIST.GET_LIST, { params });
        return data;
    },

    createWishlist: async (params: { name: string }): Promise<ResponseType<WishlistDTO>> => {
        const { data } = await AxiosClient.post(API.WISHLIST.CREATE, params);
        return data;
    },

    deleteWishlist: async (id: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.WISHLIST.DELETE}/${id}`);
        return data;
    },

    updateWishlist: async (params: { id: string; name: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.WISHLIST.UPDATE, params);
        return data;
    },

    createWishlistItem: async (params: {
        wishlistId: string;
        productBranchId: string;
        quantity: string;
    }): Promise<CreateProductByWishlistBranchResponse> => {
        const { data } = await AxiosClient.post(API.WISHLIST.CREATE_PRODUCT, params);
        return data;
    },

    updateWishlistProductList: async (params: UpdateWishlistProductListParams): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.WISHLIST.UPDATE_PRODUCT, {
            id: params.productId,
            quantity: params.quantity,
        });
        return data;
    },

    getListProductOfWishlist: async (params: {
        wishlistId: string;
    }): Promise<ResponseType<ProductByWishlistBranchDTO[]>> => {
        const { data } = await AxiosClient.get(API.WISHLIST.GET_LIST_PRODUCTS, { params });
        return data;
    },

    getListProductOfWishlistByBranch: async (params: {
        wishlistId: string;
        showByBranch: boolean;
    }): Promise<ResponseType<BranchByWishlistDTO[]>> => {
        const { data } = await AxiosClient.get(API.WISHLIST.GET_LIST_PRODUCTS, { params });
        return data;
    },

    removeProductFromWishlist: async (params: { id: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(API.WISHLIST.REMOVE_PRODUCT, { params });
        return data;
    },

    getListAllAllergen: async (params?: { searchName?: string }): Promise<ResponseType<AllergenDTO[]>> => {
        const { data } = await AxiosClient.get(API.ALLERGEN.GET_LIST, { params });
        return data;
    },

    getListAllergenByCustomer: async (params: {
        sortDirection?: SortType;
    }): Promise<ResponseType<AllergenByCustomer[]>> => {
        const { data } = await AxiosClient.get(API.ALLERGEN.GET_LIST_BY_CUSTOMER, { params });
        return data;
    },

    createAllergenByCustomer: async (params: { allergenId: string }): Promise<ResponseType<AllergenByCustomer>> => {
        const { data } = await AxiosClient.post(API.ALLERGEN.CREATE_BY_CUSTOMER, params);
        return data;
    },

    deleteAllergenByCustomer: async (allergenId: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.ALLERGEN.DELETE_BY_CUSTOMER}/${allergenId}`);
        return data;
    },

    getListIngredientByCustomer: async (params: {
        sortDirection?: SortType;
    }): Promise<ResponseType<IngredientByCustomer[]>> => {
        const { data } = await AxiosClient.get(API.INGREDIENT.GET_LIST_BY_CUSTOMER, { params });
        return data;
    },

    getListIngredientFromAllergenByCustomer: async (params: {
        allergenId: string;
    }): Promise<ResponseType<IngredientFromAllergens[]>> => {
        const { data } = await AxiosClient.get(API.INGREDIENT.GET_LIST_BY_ALLERGEN, { params });
        return data;
    },

    createIngredientByCustomer: async (params: {
        ingredientId: string;
    }): Promise<ResponseType<IngredientByCustomer>> => {
        const { data } = await AxiosClient.post(API.INGREDIENT.CREATE_BY_CUSTOMER, params);
        return data;
    },

    deleteIngredientByCustomer: async (ingredientId: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.INGREDIENT.DELETE_BY_CUSTOMER}/${ingredientId}`);
        return data;
    },

    getImage: async (imageUrl: string): Promise<ArrayBuffer> => {
        const { data } = await AxiosClient.get(API.WISHLIST.GET_IMAGE, {
            responseType: 'arraybuffer',
            params: { imageUrl },
        });
        return data;
    },

    getListConsumptionTrackerByDate: async (
        params: ScheduleProps,
    ): Promise<ResponseType<ConsumptionTrackerDisplay[]>> => {
        const { data } = await AxiosClient.get(API.CONSUMPTION_TRACKER.GET_LIST_CONSUMPTION_TRACKER, { params });
        return data;
    },

    getListConsumptionTrackerBySearch: async (params: {
        searchValue: string;
        stateFilter: string;
    }): Promise<ResponseType<ConsumptionTrackerDisplay[]>> => {
        const { data } = await AxiosClient.get(API.CONSUMPTION_TRACKER.GET_LIST_CONSUMPTION_TRACKER_BY_SEARCH, {
            params,
        });
        return data;
    },

    createConsumptionTracker: async (
        params: ConsumptionTrackerCreateType,
    ): Promise<ResponseType<ConsumptionTrackerDTO>> => {
        const { data } = await AxiosClient.post(API.CONSUMPTION_TRACKER.CREATE, {
            ...params,
        });
        return data;
    },

    updateConsumptionTracker: async (
        params: ConsumptionTrackerUpdateType,
    ): Promise<ResponseType<ConsumptionTrackerDTO>> => {
        const { data } = await AxiosClient.put(API.CONSUMPTION_TRACKER.UPDATE, {
            ...params,
        });
        return data;
    },

    deleteConsumptionTracker: async (id: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(API.CONSUMPTION_TRACKER.DELETE + id);
        return data;
    },

    getAllProductFromConsumptionTracker: async (params: {
        consumptionTrackerId: string;
    }): Promise<ResponseType<ProductConsumptionTrackerResopnse[]>> => {
        const { data } = await AxiosClient.get(API.CONSUMPTION_TRACKER.GET_LIST_PRODUCTS, { params });
        return data;
    },

    checkProductHasAllergenFromConsumptionTracker: async (params: {
        productId: string;
    }): Promise<ResponseType<CheckProductResponseType>> => {
        const { data } = await AxiosClient.get(API.CHECK_PRODUCT, { params });
        return data;
    },
};

export default userServices;
