import axios, { AxiosResponse } from 'axios';

import { adminRoute, route } from '../constants/routes';

import { storage, storageKey } from './storage';

export const baseURL = process.env.REACT_APP_API_ENDPOINT;
const baseGeocodingUrl = process.env.REACT_APP_GEOCODING_API_ENDPOINT;
const unauthorizedStatusCodes = [403, 401];

const axiosHandleUnauthorized = (response: AxiosResponse, storageKeys: string[], initialRoute: string) => {
    if (unauthorizedStatusCodes.includes(response.data.code) && response.config.headers.Authorization.length) {
        storageKeys.forEach((key) => {
            storage.removeToken(key);
        });
        window.location.pathname = initialRoute;
    }
};

export const AxiosClient = axios.create({
    baseURL,
    timeout: 25000,
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosClient.interceptors.request.use(
    async (config) => {
        let token = null;
        try {
            token = storage.getToken(storageKey.USER_TOKEN);
        } catch (e) {
            console.log('Token Invalid', e);
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

AxiosClient.interceptors.response.use(
    (response) => {
        axiosHandleUnauthorized(response, [storageKey.USER_TOKEN, storageKey.AUTH, storageKey.USER_INFO], route.LOGIN);
        return response;
    },
    (error) => {
        throw Error(error);
    },
);

export const AxiosAdminClient = axios.create({
    baseURL,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosAdminClient.interceptors.request.use(
    async (config) => {
        let adminToken = null;
        try {
            adminToken = storage.getToken(storageKey.ADMIN_TOKEN);
        } catch (e) {
            console.log('Token Invalid', e);
        }
        if (adminToken) {
            config.headers.Authorization = `Bearer ${adminToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

AxiosAdminClient.interceptors.response.use(
    (response) => {
        axiosHandleUnauthorized(response, [storageKey.ADMIN_TOKEN], adminRoute.LOGIN);
        return response;
    },
    (error) => {
        throw Error(error);
    },
);

export const AxiosGeocodingClient = axios.create({
    baseURL: baseGeocodingUrl,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosGeocodingClient.interceptors.request.use(
    async (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

AxiosGeocodingClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        throw Error(error);
    },
);
