import { CategoryDTO } from '../../common/services/apiTypes';

export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';

interface SetCurrentCategory {
    type: typeof SET_CURRENT_CATEGORY;
    payload: { [key: string]: CategoryDTO | undefined };
}

type CategoryType = SetCurrentCategory;

export type { CategoryType, SetCurrentCategory };
