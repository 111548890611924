import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// Import all reducers here
import authReducer, { AuthState } from './auth/reducer';
import locationReducer, { LocationState } from './location/reducer';
import userReducer, { UserState } from './user/reducer';
import categoryReducer, { CategoryState } from './category/reducer';
import categoryEndUserReducer, { CategoryEndUserState } from './end-user/category/reducer';
import wishlistReducer, { WishlistState } from './end-user/wishlist/reducer';
import allergenAdminReducer, { AllergenAdminState } from './admin/allergen/reducer';
import excludedIngredientReducer, { ExcludedIngredientState } from './end-user/ingredient/reducer';
// Import all sagas here
import authSagas from './auth/sagas';
import locationSagas from './location/sagas';
import userSagas from './user/sagas';
import categoryEndUserSagas from './end-user/category/sagas';
import wishlistSagas from './end-user/wishlist/sagas';
import allergenSagas from './admin/allergen/sagas';
import excludedEndUserSagas from './end-user/ingredient/sagas';

export interface GlobalState {
    auth: AuthState;
    location: LocationState;
    user: UserState;
    category: CategoryState;
    categoryEndUser: CategoryEndUserState;
    wishlistEndUser: WishlistState;
    allergenAdmin: AllergenAdminState;
    excludedIngredientEndUser: ExcludedIngredientState;
}

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['isLoggedIn'],
    blacklist: ['token', 'isLoggingIn', 'loginMessage', 'status'],
};

const locationPersistConfig = {
    key: 'location',
    storage,
};

const userPersistConfig = {
    key: 'user',
    storage,
};

const excludedIngredientConfig = {
    key: 'excludedIngredientEndUser',
    storage,
    whitelist: ['type', 'mode', 'excludedAllergens', 'excludedIngredients'],
    blacklist: ['customerAllergenList', 'customerIngredientList'],
};

export const combinedReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    location: persistReducer(locationPersistConfig, locationReducer),
    user: persistReducer(userPersistConfig, userReducer),
    category: categoryReducer,
    categoryEndUser: categoryEndUserReducer,
    wishlistEndUser: wishlistReducer,
    allergenAdmin: allergenAdminReducer,
    excludedIngredientEndUser: persistReducer(excludedIngredientConfig, excludedIngredientReducer),
});

export const rootSaga = function* () {
    yield all([
        authSagas,
        locationSagas,
        userSagas,
        categoryEndUserSagas,
        wishlistSagas,
        allergenSagas,
        excludedEndUserSagas,
    ]);
};
