import { UpdateWishlistProductListParams } from '../../../common/services/apiTypes';

export const GET_WISHLIST = 'GET_WISHLIST';
export const SET_WISHLIST = 'SET_WISHLIST';
export const GET_ACTIVE_PRODUCT_LIST = 'GET_ACTIVE_PRODUCT_LIST';
export const GET_ACTIVE_BRANCH_LIST = 'GET_ACTIVE_BRANCH_LIST';

export const SET_SELECTED_WISHLIST = 'SET_SELECTED_WISHLIST';
export const SET_ACTIVE_PRODUCT_LIST = 'SET_ACTIVE_PRODUCT_LIST';
export const SET_ACTIVE_BRANCH_LIST = 'SET_ACTIVE_BRANCH_LIST';
export const SET_SELECTED_PRODUCT_BRANCH_ID = 'SET_SELECTED_PRODUCT_BRANCH_ID';

export const UPDATE_ACTIVE_PRODUCT_LIST = 'UPDATE_ACTIVE_PRODUCT_LIST';
export const UPDATE_ACTIVE_PRODUCT_LIST_SUCCESS = 'UPDATE_ACTIVE_PRODUCT_LIST_SUCCESS';

export interface UpdateActiveProductList {
    type: typeof UPDATE_ACTIVE_PRODUCT_LIST;
    payload: UpdateWishlistProductListParams;
}

export interface GetActiveProductList {
    type: typeof GET_ACTIVE_PRODUCT_LIST;
    payload: { wishlistId: string };
}

export interface GetActiveBranchList {
    type: typeof GET_ACTIVE_BRANCH_LIST;
    payload: { wishlistId: string };
}
