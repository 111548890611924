export enum VerificationPage {
    LOGIN = 'LOGIN',
    SIGN_UP = 'SIGN_UP',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export enum SortType {
    ASC = 'asc',
    DESC = 'desc',
}

export enum ApiCode {
    NOT_FOUND = '404',
    CONFLICT = '409',
}

export enum ViewMode {
    ACCUMULATED_VIEW = 'ACCUMULATED_VIEW',
    MONTH_VIEW = 'MONTH_VIEW',
}

export enum ChartColorScheme {
    RED = 'rgba(255, 99, 132, 0.2)',
    BLUE = 'rgba(54, 162, 235, 0.2)',
    YELLOW = 'rgba(255, 206, 86, 0.2)',
    GREEN = 'rgba(75, 192, 192, 0.2)',
    PURPLE = 'rgba(153, 102, 255, 0.2)',
    RED_BORDER = 'rgba(255, 99, 132, 1)',
    BLUE_BORDER = 'rgba(54, 162, 235, 1)',
    YELLOW_BORDER = 'rgba(255, 206, 86, 1)',
    GREEN_BORDER = 'rgba(75, 192, 192, 1)',
    PURPLE_BORDER = 'rgba(153, 102, 255, 1)',
}

export enum ExcludedType {
    ALLERGEN_GROUP,
    INGREDIENTS,
}

export enum ExcludedMode {
    OFF,
    ON,
}

export enum ViewModeSchedule {
    DAY = 'day',
    WEEK = 'week',
}

export enum FILTER_CONSUMPTION_STATUS {
    ALL = 'ALL',
    WITH_ALLERGEN = 'WITH_ALLERGEN',
    WITHOUT_ALLERGEN = 'WITHOUT_ALLERGEN',
}
