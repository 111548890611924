import KrogerLogo from '../assets/images/Kroger-logo.png';

export enum WindowSize {
    SMALL = 576,
    MEDIUM = 768,
    LARGE = 992,
    EXTRA_LARGE = 1200,
}

export interface TagType {
    id: string;
    text: string;
}

export const Retailers = [
    {
        image: KrogerLogo,
        name: 'Kroger',
        nameRetailer: 'The Kroger Co.',
        country: 'United States of America',
        quantityBranch: 10,
        quantityProduct: 148,
    },
];

export const defaultLocation = {
    country: 'United State',
    state: 'Ohio',
    abbreviation: 'OH',
    countryCode: 'US',
    postCode: '43215',
};

export const sortProductValue = {
    ProductNameAsc: 'ProductNameAsc',
    ProductNameDesc: 'ProductNameDesc',
    BrandNameAsc: 'BrandNameAsc',
    BrandNameDesc: 'BrandNameDesc',
    PriceAsc: 'PriceAsc',
    PriceDesc: 'PriceDesc',
    TopViewDesc: 'TopViewDesc',
};

export interface DropdownItem {
    text: string;
    value: string;
}

export const sortItems: DropdownItem[] = [
    { text: 'Top view products', value: sortProductValue.TopViewDesc },
    { text: 'A to Z (Product Name)', value: sortProductValue.ProductNameAsc },
    { text: 'Z to A (Product Name)', value: sortProductValue.ProductNameDesc },
    { text: 'A to Z (Brand Name)', value: sortProductValue.BrandNameAsc },
    { text: 'Z to A (Brand Name)', value: sortProductValue.BrandNameDesc },
    { text: 'Price Low to High', value: sortProductValue.PriceAsc },
    { text: 'Price High to Low', value: sortProductValue.PriceDesc },
];

export const PageSizeNotification = 20;
