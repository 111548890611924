import { CategoryDTO } from '../../../common/services/apiTypes';

import { CATEGORY_ACTION, GetCategories, GetCategoriesSuccess } from './actionTypes';

export const getCategories = (): GetCategories => {
    return {
        type: CATEGORY_ACTION.GET_CATEGORIES,
    };
};

export const getCategorySuccess = (payload: CategoryDTO[]): GetCategoriesSuccess => {
    return {
        type: CATEGORY_ACTION.GET_CATEGORIES_SUCCESS,
        payload,
    };
};
