import { call, put, takeEvery } from 'redux-saga/effects';

import userServices from '../../common/services/userServices';
import { ResponseType } from '../../common/services/apiTypes';
import { storage, storageKey } from '../../common/utils/storage';
import { route } from '../../common/constants/routes';

import { LOGIN, LoginAction, LoginResponse } from './actionTypes';
import { loginSuccess, loginFailure } from './actions';

function* loginSaga(action: LoginAction) {
    try {
        const response: ResponseType<LoginResponse | null> = yield call(() => userServices.login(action.payload));
        if (response.success && !!response.data?.token) {
            storage.setToken(response.data.token, storageKey.USER_TOKEN);
            yield put(loginSuccess({ token: response.data.token, status: response.data.status }));
            window.location.pathname = route.HOME;
        } else {
            yield put(loginFailure({ loginMessage: response.message, status: response.data?.status }));
        }
    } catch (error) {
        yield put(loginFailure({ loginMessage: 'Login Failure. Please try again!' }));
    }
}

function* watchAll() {
    yield takeEvery(LOGIN, loginSaga);
}

export default watchAll();
