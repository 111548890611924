import { CountriesResponse, PostCodeResponse } from '../../common/services/apiTypes';

export const GET_LOCATION = 'GET_LOCATION';

export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';

export interface Location {
    countries: CountriesResponse[];
    postcodes: PostCodeResponse[];
    currentCountry: string;
    currentState: string;
}

export type CurrentLocation = Omit<Location, 'countries' | 'postcodes'>;

interface GetLocation {
    type: typeof GET_LOCATION;
}

interface GetLocationSuccess {
    type: typeof GET_LOCATION_SUCCESS;
    payload: Location;
}

interface SetCurrentLocation {
    type: typeof SET_CURRENT_LOCATION;
    payload: CurrentLocation;
}

type LocationType = GetLocation | GetLocationSuccess | SetCurrentLocation;

export type { LocationType, GetLocation, GetLocationSuccess, SetCurrentLocation };
