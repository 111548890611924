import React from 'react';

import './styles.scss';

const LoadingSpinner: React.FC = () => {
    return (
        <div className="wrap">
            <div className="loading">
                <div className="bounce-ball"></div>
                <div className="text">LOADING</div>
            </div>
        </div>
    );
};

export default LoadingSpinner;
