import { CategoryDTO } from '../../../common/services/apiTypes';
import { CategoryTypes } from '../../end-user/category/actionTypes';

import { CATEGORY_ACTION } from './actionTypes';

export interface CategoryEndUserState {
    listCategory: CategoryDTO[];
}

const initialState: CategoryEndUserState = {
    listCategory: [],
};

const reducer = (state = initialState, action: CategoryTypes): CategoryEndUserState => {
    switch (action.type) {
        case CATEGORY_ACTION.GET_CATEGORIES_SUCCESS:
            return { ...state, listCategory: action.payload };
        default:
            return state;
    }
};

export default reducer;
