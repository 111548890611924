import {
    GetLocation,
    GetLocationSuccess,
    Location,
    GET_LOCATION,
    GET_LOCATION_SUCCESS,
    SetCurrentLocation,
    SET_CURRENT_LOCATION,
    CurrentLocation,
} from './actionTypes';

export const getLocation = (): GetLocation => {
    return {
        type: GET_LOCATION,
    };
};

export const getLocationSuccess = (payload: Location): GetLocationSuccess => {
    return {
        type: GET_LOCATION_SUCCESS,
        payload,
    };
};

export const setCurrentLocation = (payload: CurrentLocation): SetCurrentLocation => {
    return {
        type: SET_CURRENT_LOCATION,
        payload,
    };
};
