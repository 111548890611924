import { call, put, select, takeEvery } from 'redux-saga/effects';

import { defaultLocation } from '../../common/constants/app';
import { CountriesResponse, PostCodeResponse, ResponseType, UserInfoDTO } from '../../common/services/apiTypes';
import userServices from '../../common/services/userServices';
import { baseURL } from '../../common/utils/axiosClient';
import { setCurrentLocation } from '../location/actions';
import { getCountries, getPostCodes } from '../location/selector';

import { getUserInfoSuccess } from './actions';
import { GET_USER_INFO } from './actionTypes';

function* getUserInfo() {
    try {
        const response: ResponseType<UserInfoDTO | null> = yield call(() => userServices.getUserInfo());
        if (response.success && response.data) {
            const {
                email,
                firstName,
                lastName,
                postCodeId,
                sex,
                address,
                avatar,
                excludeShowWarningProductIds,
                excludeProductIds,
            } = response.data;
            const postCodes: PostCodeResponse[] = yield select(getPostCodes);
            const countries: CountriesResponse[] = yield select(getCountries);
            const userPostCode = postCodes.find((state) => state.id === postCodeId);
            let userCountry =
                countries.find((country) => country.id === userPostCode?.countryId)?.isoCode ||
                defaultLocation.countryCode;
            let userState = userPostCode?.state || defaultLocation.abbreviation;
            if (userCountry !== defaultLocation.countryCode) {
                userCountry = defaultLocation.countryCode;
                userState = defaultLocation.abbreviation;
            }
            yield put(setCurrentLocation({ currentState: userState, currentCountry: userCountry }));
            yield put(
                getUserInfoSuccess({
                    email,
                    firstName,
                    lastName,
                    postCodeId,
                    sex,
                    address,
                    avatar: avatar ? `${baseURL}customer/avatar/${avatar}` : null,
                    excludeShowWarningProductIds,
                    excludeProductIds,
                }),
            );
        }
    } catch (error) {}
}

function* watchAll() {
    yield takeEvery(GET_USER_INFO, getUserInfo);
}

export default watchAll();
