import { createAction } from 'redux-actions';

import {
    LoginFailureResponse,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET,
    LoginParams,
    LoginResponse,
} from './actionTypes';

export const login = createAction<LoginParams>(LOGIN);

export const loginSuccess = createAction<LoginResponse>(LOGIN_SUCCESS);

export const loginFailure = createAction<LoginFailureResponse>(LOGIN_FAILURE);

export const reset = createAction<void>(RESET);
