import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { applyMiddleware, createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { combinedReducer, rootSaga } from './redux';
import './index.scss';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', 'location', 'user', 'category', 'categoryEndUser', 'wishlistEndUser', 'allergenAdmin'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
