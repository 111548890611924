import { createAction } from 'redux-actions';

import { AllergenDTO, IngredientFromAllergens } from '../../../common/services/apiTypes';

import { ALLERGEN_ACTION, SET_IS_TABLE_NEED_RELOAD, SET_SELECTED_INGREDIENTS } from './actionTypes';

export const setSelectedIngredients = createAction<IngredientFromAllergens[]>(SET_SELECTED_INGREDIENTS);
export const setIsTableNeedReload = createAction<boolean>(SET_IS_TABLE_NEED_RELOAD);
export const setAllergenList = createAction<AllergenDTO[]>(ALLERGEN_ACTION.SET_LIST);
export const setSelectedAllergen = createAction<AllergenDTO | undefined>(ALLERGEN_ACTION.SET_SELECTED_ALLERGEN);
export const getAllergenList = createAction(ALLERGEN_ACTION.GET_LIST);
