import { CategoryDTO } from '../../common/services/apiTypes';

import { CategoryType, SET_CURRENT_CATEGORY } from './actionTypes';

export interface CategoryState {
    mainCategory: CategoryDTO | undefined;
    firstSubCategory: CategoryDTO | undefined;
    secondSubCategory: CategoryDTO | undefined;
    thirdSubCategory: CategoryDTO | undefined;
}

const initialState: CategoryState = {
    mainCategory: undefined,
    firstSubCategory: undefined,
    secondSubCategory: undefined,
    thirdSubCategory: undefined,
};

const reducer = (state = initialState, action: CategoryType): CategoryState => {
    switch (action.type) {
        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
