import { createAction } from 'redux-actions';

import {
    BranchByWishlistDTO,
    ProductByWishlistBranchDTO,
    UpdateWishlistProductListParams,
    WishlistDTO,
} from '../../../common/services/apiTypes';

import {
    GET_WISHLIST,
    SET_WISHLIST,
    SET_SELECTED_WISHLIST,
    UPDATE_ACTIVE_PRODUCT_LIST,
    SET_ACTIVE_PRODUCT_LIST,
    GET_ACTIVE_PRODUCT_LIST,
    UPDATE_ACTIVE_PRODUCT_LIST_SUCCESS,
    GET_ACTIVE_BRANCH_LIST,
    SET_ACTIVE_BRANCH_LIST,
    SET_SELECTED_PRODUCT_BRANCH_ID,
} from './actionTypes';

export const getWishlist = createAction(GET_WISHLIST);
export const getActiveProductList = createAction<{ wishlistId: string }>(GET_ACTIVE_PRODUCT_LIST);
export const getActiveBranchList = createAction<{ wishlistId: string }>(GET_ACTIVE_BRANCH_LIST);

export const setActiveBranchList = createAction<BranchByWishlistDTO[]>(SET_ACTIVE_BRANCH_LIST);
export const setSelectedWishlist = createAction<WishlistDTO | undefined>(SET_SELECTED_WISHLIST);
export const setWishlist = createAction<WishlistDTO[]>(SET_WISHLIST);
export const setActiveProductList = createAction<ProductByWishlistBranchDTO[]>(SET_ACTIVE_PRODUCT_LIST);
export const setSelectedProductBranchId = createAction<string[]>(SET_SELECTED_PRODUCT_BRANCH_ID);

export const updateActiveProductList = createAction<UpdateWishlistProductListParams>(UPDATE_ACTIVE_PRODUCT_LIST);
export const updateActiveProductListSuccess = createAction<UpdateWishlistProductListParams>(
    UPDATE_ACTIVE_PRODUCT_LIST_SUCCESS,
);
