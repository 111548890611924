import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ResponseType, AllergenByCustomer, IngredientByCustomer } from '../../../common/services/apiTypes';
import userServices from '../../../common/services/userServices';

import { setCustomerAllergenList, setCustomerIngredientList } from './actions';
import {
    GetCustomerAllergenList,
    GetCustomerIngredientList,
    GET_CUSTOMER_ALLERGEN_LIST,
    GET_CUSTOMER_INGREDIENT_LIST,
} from './actionTypes';

function* getCustomerAllergenList(action: GetCustomerAllergenList) {
    try {
        const response: ResponseType<AllergenByCustomer[]> = yield call(() =>
            userServices.getListAllergenByCustomer(action.payload),
        );
        yield put(setCustomerAllergenList(response.data || []));
    } catch (error) {}
}

function* getCustomerIngredientList(action: GetCustomerIngredientList) {
    try {
        const response: ResponseType<IngredientByCustomer[]> = yield call(() =>
            userServices.getListIngredientByCustomer(action.payload),
        );
        yield put(setCustomerIngredientList(response.data || []));
    } catch (error) {}
}

function* watchAll() {
    yield all([
        takeEvery(GET_CUSTOMER_ALLERGEN_LIST, getCustomerAllergenList),
        takeEvery(GET_CUSTOMER_INGREDIENT_LIST, getCustomerIngredientList),
    ]);
}

export default watchAll();
