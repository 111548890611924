import { createAction } from 'redux-actions';

import { TagType } from '../../../common/constants/app';
import { ExcludedMode, ExcludedType, SortType } from '../../../common/constants/enum';
import { AllergenByCustomer, IngredientByCustomer } from '../../../common/services/apiTypes';

import {
    GET_CUSTOMER_ALLERGEN_LIST,
    GET_CUSTOMER_INGREDIENT_LIST,
    SET_CUSTOMER_ALLERGEN_LIST,
    SET_CUSTOMER_INGREDIENT_LIST,
    SET_DELETED_ID_LIST,
    SET_EXCLUDED_ALLERGEN,
    SET_EXCLUDED_INGREDIENT,
    SET_EXCLUDED_MODE,
    SET_EXCLUDED_TYPE,
} from './actionTypes';

export const setExcludedIngredient = createAction<TagType[]>(SET_EXCLUDED_INGREDIENT);
export const setExcludedAllergen = createAction<TagType[]>(SET_EXCLUDED_ALLERGEN);
export const setExcludedType = createAction<ExcludedType>(SET_EXCLUDED_TYPE);
export const setExcludedMode = createAction<ExcludedMode>(SET_EXCLUDED_MODE);
export const setCustomerAllergenList = createAction<AllergenByCustomer[]>(SET_CUSTOMER_ALLERGEN_LIST);
export const setCustomerIngredientList = createAction<IngredientByCustomer[]>(SET_CUSTOMER_INGREDIENT_LIST);
export const setDeletedIdList = createAction<string[]>(SET_DELETED_ID_LIST);

export const getCustomerAllergenList = createAction<{ sortDirection?: SortType }>(GET_CUSTOMER_ALLERGEN_LIST);
export const getCustomerIngredientList = createAction<{ sortDirection?: SortType }>(GET_CUSTOMER_INGREDIENT_LIST);
