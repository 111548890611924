import { CategoryDTO } from '../../../common/services/apiTypes';

export enum CATEGORY_ACTION {
    GET_CATEGORIES = 'GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
}

interface GetCategories {
    type: typeof CATEGORY_ACTION.GET_CATEGORIES;
}

interface GetCategoriesSuccess {
    type: typeof CATEGORY_ACTION.GET_CATEGORIES_SUCCESS;
    payload: CategoryDTO[];
}

type CategoryTypes = GetCategories | GetCategoriesSuccess;

export type { CategoryTypes, GetCategories, GetCategoriesSuccess };
