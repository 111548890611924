import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import {
    WishlistDTO,
    ProductByWishlistBranchDTO,
    UpdateWishlistProductListParams,
    BranchByWishlistDTO,
} from '../../../common/services/apiTypes';
import { produceReducer } from '../../wrapper';

import {
    SET_ACTIVE_BRANCH_LIST,
    SET_ACTIVE_PRODUCT_LIST,
    SET_SELECTED_PRODUCT_BRANCH_ID,
    SET_SELECTED_WISHLIST,
    SET_WISHLIST,
    UPDATE_ACTIVE_PRODUCT_LIST_SUCCESS,
} from './actionTypes';

export interface WishlistState {
    wishlist: WishlistDTO[];
    selectedWishlist: WishlistDTO | undefined;
    activeProductList: ProductByWishlistBranchDTO[];
    activeBranchList: BranchByWishlistDTO[];
    selectedProductBranchId: string[];
}

const initialState: WishlistState = {
    wishlist: [],
    selectedWishlist: undefined,
    activeProductList: [],
    activeBranchList: [],
    selectedProductBranchId: [],
};

const actionHandlers = {
    [SET_WISHLIST]: (draftStore: WishlistState, action: Action<WishlistDTO[]>) => {
        draftStore.wishlist = action.payload;
    },
    [SET_SELECTED_WISHLIST]: (draftStore: WishlistState, action: Action<WishlistDTO | undefined>) => {
        draftStore.selectedWishlist = action.payload;
    },
    [SET_ACTIVE_PRODUCT_LIST]: (draftStore: WishlistState, action: Action<ProductByWishlistBranchDTO[]>) => {
        draftStore.activeProductList = action.payload;
    },
    [SET_ACTIVE_BRANCH_LIST]: (draftStore: WishlistState, action: Action<BranchByWishlistDTO[]>) => {
        draftStore.activeBranchList = action.payload;
    },
    [SET_SELECTED_PRODUCT_BRANCH_ID]: (draftStore: WishlistState, action: Action<string[]>) => {
        draftStore.selectedProductBranchId = action.payload;
    },
    [UPDATE_ACTIVE_PRODUCT_LIST_SUCCESS]: (
        draftStore: WishlistState,
        action: Action<UpdateWishlistProductListParams>,
    ) => {
        const { productId, quantity } = action.payload;
        const productList = draftStore.activeProductList.map((product) => {
            if (product.id === productId) {
                return {
                    ...product,
                    quantity,
                };
            }
            return product;
        });
        draftStore.activeProductList = productList;
        const branchList = draftStore.activeBranchList.map((branch) => {
            const newBranch = branch.wishlistData.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity,
                    };
                }
                return product;
            });
            return {
                ...branch,
                wishlistData: newBranch,
            };
        });
        draftStore.activeBranchList = branchList;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<WishlistState>;
