import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { TagType } from '../../../common/constants/app';
import { ExcludedMode, ExcludedType } from '../../../common/constants/enum';
import { AllergenByCustomer, IngredientByCustomer } from '../../../common/services/apiTypes';
import { produceReducer } from '../../wrapper';

import {
    SET_CUSTOMER_ALLERGEN_LIST,
    SET_CUSTOMER_INGREDIENT_LIST,
    SET_EXCLUDED_ALLERGEN,
    SET_EXCLUDED_INGREDIENT,
    SET_EXCLUDED_MODE,
    SET_EXCLUDED_TYPE,
    SET_DELETED_ID_LIST,
} from './actionTypes';

export interface ExcludedIngredientState {
    type: ExcludedType;
    mode: ExcludedMode;
    excludedAllergens: TagType[];
    excludedIngredients: TagType[];
    customerAllergenList: AllergenByCustomer[];
    customerIngredientList: IngredientByCustomer[];
    deletedIdList: string[];
}

const initialState: ExcludedIngredientState = {
    type: ExcludedType.ALLERGEN_GROUP,
    mode: ExcludedMode.OFF,
    excludedAllergens: [],
    excludedIngredients: [],
    customerAllergenList: [],
    customerIngredientList: [],
    deletedIdList: [],
};

const actionHandlers = {
    [SET_EXCLUDED_ALLERGEN]: (draftStore: ExcludedIngredientState, action: Action<TagType[]>) => {
        draftStore.excludedAllergens = action.payload;
    },
    [SET_EXCLUDED_INGREDIENT]: (draftStore: ExcludedIngredientState, action: Action<TagType[]>) => {
        draftStore.excludedIngredients = action.payload;
    },
    [SET_EXCLUDED_TYPE]: (draftStore: ExcludedIngredientState, action: Action<ExcludedType>) => {
        draftStore.type = action.payload;
    },
    [SET_EXCLUDED_MODE]: (draftStore: ExcludedIngredientState, action: Action<ExcludedMode>) => {
        draftStore.mode = action.payload;
    },
    [SET_CUSTOMER_ALLERGEN_LIST]: (draftStore: ExcludedIngredientState, action: Action<AllergenByCustomer[]>) => {
        draftStore.customerAllergenList = action.payload;
    },
    [SET_CUSTOMER_INGREDIENT_LIST]: (draftStore: ExcludedIngredientState, action: Action<IngredientByCustomer[]>) => {
        draftStore.customerIngredientList = action.payload;
    },
    [SET_DELETED_ID_LIST]: (draftStore: ExcludedIngredientState, action: Action<string[]>) => {
        draftStore.deletedIdList = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<ExcludedIngredientState>;
