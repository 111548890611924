import { call, put, takeEvery } from 'redux-saga/effects';

import commonServices from '../../common/services/commonServices';
import { ResponseType, CountriesResponse, PostCodeResponse } from '../../common/services/apiTypes';

import { GET_LOCATION } from './actionTypes';
import { getLocationSuccess } from './actions';

function* getLocation() {
    try {
        const response: {
            countryRes: ResponseType<{ countries: CountriesResponse[] }>;
            postcodeRes: ResponseType<{ postcodes: PostCodeResponse[] }>;
        } = yield call(() => commonServices.getLocation());
        if (response.countryRes.success && response.postcodeRes.success) {
            yield put(
                getLocationSuccess({
                    countries: response.countryRes.data.countries,
                    postcodes: response.postcodeRes.data.postcodes,
                    currentCountry: '',
                    currentState: '',
                }),
            );
        }
    } catch (error) {}
}

function* watchAll() {
    yield takeEvery(GET_LOCATION, getLocation);
}

export default watchAll();
