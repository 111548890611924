import React from 'react';
import './App.scss';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { route, RouteConfig } from './common/constants/routes';
import { getLoadableHelper } from './common/helpers/LoadableHelper';

const UserHome = getLoadableHelper(() => import('./pages/user/User'));

const AdminHome = getLoadableHelper(() => import('./pages/admin/Admin'));

const App = (): JSX.Element => {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route exact path={route.HOME} component={UserHome} />
                    {/* admin page */}
                    <Route path={RouteConfig.ADMIN} component={AdminHome} />
                    {/* user page */}
                    <Route path={RouteConfig.USER} component={UserHome} />
                    <Redirect to={route.HOME} />
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default App;
